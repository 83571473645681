import React, { useState } from "react";
import {
  LayersControl,
  MapContainer,
  TileLayer,
  WMSTileLayer,
} from "react-leaflet";
import geoServerApi from "config/geoServerApi";
import CustomerPropertyListing from "components/dashboard/CustomerPropertyListing";
import DropPin from "components/properties/DropPin";
import { mapConfig } from "config/mapConfig";

const MapPrivate = (props) => {
  const { customerProperties } = props;
  const minZoom = 1;
  const maxZoom = 22;
  const [center, setCenter] = useState({ lat: 33.684511, lon: 72.823963 });
  const [mapHook, setMapHook] = useState();
  return (
    <MapContainer
      center={center}
      minZoom={minZoom}
      maxZoom={maxZoom}
      zoom={12}
      whenCreated={(map) => {
        setMapHook(map);
      }}
      style={{ maxHeight: "90vh" }}
    >
      <LayersControl position="topright">
        <LayersControl.BaseLayer name="Satellite View">
          <TileLayer
            attribution={mapConfig.google.attribution}
            url={mapConfig.google.url}
            maxZoom={maxZoom}
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer checked name="Street View">
          <TileLayer
            attribution={mapConfig.openstreet.attribution}
            url={mapConfig.openstreet.url}
            maxZoom={maxZoom}
          />
        </LayersControl.BaseLayer>
        <LayersControl.Overlay checked name="City View">
            <WMSTileLayer
              url={geoServerApi.baseUrl}
              version="1.1.0"
              layers={geoServerApi.cityLayer}
              transparent="true"
              opacity="0.3"
              format="image/png"
              maxZoom={maxZoom}
            />
          </LayersControl.Overlay>

          <LayersControl.Overlay checked name="Society View">
            <WMSTileLayer
              url={geoServerApi.baseUrl}
              version="1.1.0"
              layers={geoServerApi.societyLayer}
              transparent="true"
              opacity="0.6"
              format="image/png"
              maxZoom={maxZoom}
            />
          </LayersControl.Overlay>

          <LayersControl.Overlay checked name="Block View">
            <WMSTileLayer
              url={geoServerApi.baseUrl}
              version="1.1.0"
              layers={geoServerApi.blockLayer}
              transparent="true"
              opacity="0.6"
              format="image/png"
              maxZoom={maxZoom}
            />
          </LayersControl.Overlay>

          <LayersControl.Overlay checked name="Plot View">
            <WMSTileLayer
              url={geoServerApi.baseUrl}
              version="1.1.0"
              layers={geoServerApi.plotLayer}
              transparent="true"
              opacity="0.7"
              format="image/png"
              maxZoom={maxZoom}
            />
          </LayersControl.Overlay>
      </LayersControl>
      <DropPin />
      <CustomerPropertyListing customerProperties={customerProperties} />
    </MapContainer>
  );
};

export default MapPrivate;
