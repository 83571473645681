import axios from "axios";
import { useMutation } from "react-query";
import geoServerApi from "../../config/geoServerApi";

function useFeatureInfo() {
  return useMutation(async ({ centeroid, map }) => {
    let size = map.getSize(),
      crs = map.options.crs,
      bounds = map.getBounds(),
      xy = map.latLngToContainerPoint(centeroid, map.getZoom()),
      sw = crs.project(bounds.getSouthWest()),
      ne = crs.project(bounds.getNorthEast());

    let params = {
      service: "WMS",
      version: "1.1.1",
      request: "GetFeatureInfo",
      INFO_FORMAT: "application/json",
      BBOX: sw.x + "," + sw.y + "," + ne.x + "," + ne.y,
      WIDTH: size.x,
      HEIGHT: size.y,
      layers: geoServerApi.plotLayer,
      query_layers: geoServerApi.plotLayer,
      srs: crs.code,
      x: Math.round(xy.x),
      y: Math.round(xy.y),
      feature_count: 1,
    };

    return await axios
      .get(geoServerApi.baseUrl, {
        params: params,
      })
      .then((result) => {
        const features = result.data?.features;
        if (features.length > 0) {
          return features[0];
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });
}

export default useFeatureInfo;
