import "styles/app.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import Main from "components/Main";
import { AuthProvider } from "context/AuthContext";
import { ReactQueryDevtools } from 'react-query/devtools'


function App() {
  const queryClient = new QueryClient();
  queryClient.setDefaultOptions({
    queries: {
      refetchOnWindowFocus: false,
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Main />
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
