import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { axiosLaravel } from "config/axiosLaravel";
import localRoutes from "config/localRoutes";
import { AuthContext } from "context/AuthContext";
import Dashboard from "elements/dashboard/Dashboard";
import NavBar from "components/navigation/NavBar";
import HouseForRent from "../elements/HouseForRent";
import Home from "elements/Home";
import PropertiesModal from "components/properties/PropertiesModal"
const Main = () => {
  const { token, user } = useContext(AuthContext);
  axiosLaravel.defaults.headers.common = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
    "Access-Control-Allow-Origin": '*',
  };

  return (
    <>
      <NavBar />
      <main>
        <Routes>
          <Route exact path={localRoutes.home} element={<Home />} />
          {token && (
            <Route exact path={localRoutes.dashboard} element={<Dashboard />} />
          )}
          <Route exact path="houserent" element={<HouseForRent open={true}/>} />
          
          <Route exact path="modal" element={<PropertiesModal open={true}/>} />
          
        </Routes>
      </main>
    </>
  );
};

export default Main;
