export const mapConfig = {
  google: {
    attribution: "googlemaps",
    url: "https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}",
  },

  openstreet: {
    attribution: "openstreetmaps",
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  }
};
