import L from "leaflet";
import marker from '../assets/marker-icon.png';
import marker_shadow from '../assets/marker-shadow.png';

export default L.icon({
  iconSize: [20, 31],
  iconAnchor: [5, 21],
  popupAnchor: [2, -40],
  iconUrl: marker,
  shadowUrl: marker_shadow
});