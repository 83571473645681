import React, { useContext, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Button, Container, Navbar } from "react-bootstrap";
import Login from "components/auth/Login";
import Signup from "components/auth/Signup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "context/AuthContext";
import { IoHomeOutline } from "react-icons/io5";
import { BsPerson } from "react-icons/bs";
import { FaAngleDown } from "react-icons/fa";
import localRoutes from "config/localRoutes";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  const { token, user, logoutUser } = useContext(AuthContext);
  const [openLogin, setOpenLogin] = useState();
  const [openSignup, setOpenSignup] = useState();
  const navigate = useNavigate();

  const addProperty = () => {
    if (token) {
      navigate(localRoutes.dashboard, {
        state:{
          "displayMap": true,
        }
      });
      toast.info(
        "Select the marker from sidebar of the map and drop it in your desired location!"
      );
    } else{
      toast.error("Please login to continue!");
    }
  };

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
      />
      <Navbar bg="light" expand="lg">
        <Container className="navbar_content">
          <Navbar.Brand
            onClick={() => {
              navigate(localRoutes.home);
            }}
            href="#"
            className="amlaak_heading"
          >
            <img
              alt=""
              src="/images/logo.png"
              width="96"
              height="18"
              top="10"
              className="d-inline-block align-top mt-2"
            />{" "}
            {/* Amlaak */}
          </Navbar.Brand>

          <Navbar.Collapse className="justify-content-end">
            <Button onClick={addProperty} variant="outline outline_button">
              {" "}
              <IoHomeOutline className="icon_color" /> Add Property
            </Button>{" "}
            {token ? (
              <>
                <div className="person_btn">
                  <Dropdown>
                    <Dropdown.Toggle variant="outline outline_button">
                      <BsPerson
                        className="icon_color"
                        style={{ fontSize: "22px" }}
                      />
                      <FaAngleDown
                        className="icon_color"
                        style={{ marginTop: "0px", marginLeft: "12px" }}
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="text-center person_drop_content">
                      <Dropdown.Item
                        onClick={() => {
                          navigate(localRoutes.dashboard);
                        }}
                      >
                        Dashboard
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => {
                          logoutUser();
                        }}
                      >
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </>
            ) : (
              <>
                <Button
                  variant="btn-outline-primary"
                  className="outline_button"
                  onClick={() => setOpenLogin(true)}
                >
                  Login
                </Button>
                <Login
                  open={openLogin}
                  setOpen={setOpenLogin}
                  setOpenSignup={setOpenSignup}
                />
                <Signup open={openSignup} setOpen={setOpenSignup} />
              </>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
