import React, { useState } from "react";
import {
  Button,
  Container,
  Navbar,
  Dropdown,
  Modal,
  Tab,
  Tabs,
} from "react-bootstrap";
import { IoHomeOutline } from "react-icons/io5";
import { BsPerson, BsArrowDownUp } from "react-icons/bs";
import { FaAngleDown } from "react-icons/fa";
import { FiSearch, FiMenu } from "react-icons/fi";
import { BiChevronRight } from "react-icons/bi";
import Form from "react-bootstrap/Form";
function HouseForRent() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="header">
        <hr />

        <div className="container-fluid header_houserent">
          <div className="row d-block">
            <ul className="d-flex house_rent_user_list">
              <li className="d-block">
                <Dropdown style={{ display: "inline" }}>
                  <Dropdown.Toggle
                    className="House_ font_col_size"
                    id="dropdown-basic"
                  >
                    House <FaAngleDown className="angledown_for_all_btn" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="house_dropdown_houserent">
                    <Tabs
                      defaultActiveKey="Residential"
                      id="uncontrolled-tab-example"
                      className="mb-3 tab_house_dropdown"
                    >
                      <Tab eventKey="Residential" title="Residential">
                        <div className="row House_row">
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <Dropdown.Item
                              href="#/action-1"
                              className="dropdown_btn_house"
                            >
                              House
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#/action-2"
                              className="dropdown_all_btn_house"
                            >
                              Apartment
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#/action-2"
                              className="dropdown_all_btn_house"
                            >
                              Lower Portion
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#/action-2"
                              className="dropdown_all_btn_house"
                            >
                              Room
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#/action-2"
                              className="dropdown_all_btn_house"
                            >
                              Hotel Suite
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#/action-2"
                              className="dropdown_all_btn_house"
                            >
                              Annexe
                            </Dropdown.Item>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <Dropdown.Item
                              href="#/action-1"
                              className="dropdown_all_btn_house_2"
                            >
                              Guest House
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#/action-2"
                              className="dropdown_all_btn_house"
                            >
                              Upper Portion
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#/action-2"
                              className="dropdown_all_btn_house"
                            >
                              Farm House
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#/action-2"
                              className="dropdown_all_btn_house"
                            >
                              Panthouse
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#/action-2"
                              className="dropdown_all_btn_house"
                            >
                              Basement
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#/action-2"
                              className="dropdown_all_btn_house"
                            >
                              Hostel
                            </Dropdown.Item>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="Plots" title="Plots">
                        <div className="row House_row">
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <Dropdown.Item
                              href="#/action-2"
                              className="dropdown_all_btn_house"
                            >
                              Residental Plot
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#/action-2"
                              className="dropdown_all_btn_house"
                            >
                              Agricultural Land
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#/action-2"
                              className="dropdown_all_btn_house"
                            >
                              Plot File
                            </Dropdown.Item>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <Dropdown.Item
                              href="#/action-2"
                              className="dropdown_all_btn_house"
                            >
                              commerical Plot
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#/action-2"
                              className="dropdown_all_btn_house"
                            >
                              Industrial Land
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#/action-2"
                              className="dropdown_all_btn_house"
                            >
                              Farmhose Plot
                            </Dropdown.Item>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="Commercial" title="Commercial"></Tab>
                    </Tabs>
                    <Button className="price_heading_modal_ineer reset">
                      Reset
                    </Button>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li className="d-block">
                <Form.Select
                  aria-label="Default select example"
                  className="House_ font_col_size"
                >
                  <FaAngleDown className="angledown_for_all_btn" />
                  <option className="text-dark">Rent </option>
                  <option className="text-dark">Sale</option>
                  <option className="text-dark">Two</option>
                  <option className="text-dark">Three</option>
                </Form.Select>
              </li>
              <li className="d-block">
                <Dropdown
                  className="dropdown_btn_houserent_header"
                  id="price_range_mobile"
                >
                  <Dropdown.Toggle
                    className="price_range_btn_ font_col_size"
                    id="dropdown-basic"
                  >
                    Price Range{" "}
                    <FaAngleDown className="angledown_for_all_btn" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="pricerang_dropdown_houserent">
                    <Form.Label className="Price_label m-0 ">
                      {" "}
                      <h3> Price Range</h3>{" "}
                    </Form.Label>
                    <Form.Range />
                    <div className="style_range_slider_form_wrapper__2AXhS d-flex">
                      <div className="style_range_slider_field__u9Co9">
                        <label>Min</label>
                        <input
                          type="number"
                          value="0"
                          style={{ width: "100%;" }}
                        />
                      </div>
                      <div
                        className="style_range_slider_field__u9Co9"
                        style={{ marginLeft: "30px" }}
                      >
                        <label>Max</label>
                        <input name="maxValue" type="number" value="100" />
                      </div>
                    </div>
                    <Button className="price_heading_modal_ineer">Done</Button>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li className="d-block">
                <Dropdown
                  className="dropdown_btn_houserent_header"
                  id="size_range_mobile"
                >
                  <Dropdown.Toggle
                    className="price_range_btn_ font_col_size"
                    id="dropdown-basic"
                  >
                    Size Range <FaAngleDown className="angledown_for_all_btn" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="pricerang_dropdown_houserent">
                    <Form.Label className="Price_label m-0">
                      {" "}
                      <h3> Size Range</h3>{" "}
                    </Form.Label>

                    <Dropdown style={{ marginLeft: "7px" }}>
                      <span>Area Unit :</span>
                      <Form.Select
                        aria-label="Default select example"
                        className="marla_area_unit"
                      >
                        <FaAngleDown className="angledown_for_all_btn" />
                        <option className="text-dark">Marla </option>
                        <option className="text-dark">Kanal</option>
                        <option className="text-dark">Square Feet</option>
                        <option className="text-dark">Square Meters</option>
                        <option className="text-dark">Acre</option>
                      </Form.Select>
                    </Dropdown>
                    <Form.Range className="mt-3" />
                    <div className="style_range_slider_form_wrapper__2AXhS d-flex">
                      <div className="style_range_slider_field__u9Co9">
                        <label>Min</label>
                        <input
                          type="number"
                          value="0"
                          style={{ width: "100%;" }}
                        />
                      </div>
                      <div
                        className="style_range_slider_field__u9Co9"
                        style={{ marginLeft: "30px" }}
                      >
                        <label>Max</label>
                        <input name="maxValue" type="number" value="100" />
                      </div>
                    </div>
                    <Button className="price_heading_modal_ineer">Done</Button>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li className="d-block">
                <div
                  id="menu_range_mobile"
                  style={{ display: "inline", paddingLeft: "0px" }}
                >
                  <Button className="price_rang_btn " onClick={handleShow}>
                    {" "}
                    More
                    <FiMenu
                      className="angledown_for_all_btn"
                      style={{ fontSize: "20px", marginTop: "-2px" }}
                    />
                  </Button>
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Woohoo, you're reading this text in a modal!
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={handleClose}>
                        Save Changes
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="container-fluid" style={{ paddingLeft: "29px" }}>
          <div className="row">
            <ul className="header_link_read">
              <li>
                <a href="#">
                  Amlaak <BiChevronRight className="chevronright_icon" />{" "}
                </a>
              </li>
              <li>
                <a href="#">House for Rent in Rawalpindi </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="container-fluid" style={{ paddingLeft: "29px" }}>
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-5 col-12">
              <div className="header_filter">
                <h1>
                  <span>Houses for Rent in Chaklala Scheme, Rawalpindi</span>
                </h1>
                <div className="properties_default mt-4">
                  <span className="properties">50 properties</span>
                  <Dropdown>
                    <Dropdown.Toggle
                      className="default_order"
                      id="dropdown-basic"
                    >
                      Default order{" "}
                      <BsArrowDownUp style={{ color: "#ff5b00" }} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown_menu_default">
                      <Dropdown.Item href="#/action-1">
                        Default Order
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Newest Listed
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Oldest Listed
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Highest Price
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Lowest Price
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <hr />
            </div>

            <div className="col-lg-7 col-md-7 col-sm-7 col-12"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HouseForRent;
