import React, { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import PropertiesModal from "components/properties/PropertiesModal";

const CustomerPropertyListing = (props) => {
  const { customerProperties } = props;

  const map = useMap();
  const [selectedProperty, setSelectedProperty] = useState();
  const [showModal, setShowModal] = useState(false);

  // used useState because it was showing error on simple variable
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    removeMarkers();
    addMarkers();
  }, [customerProperties]);

  const removeMarkers = () => {
    while (markers.length > 0) {
      let marker = markers.pop();
      map.removeLayer(marker);
    }
  };

  const addMarkers = () => {
    customerProperties?.forEach((listing) => {
      let marker = new L.circleMarker([listing.lat, listing.lng], {
        radius: 7,
        fillColor: "#f03",
        color: "white",
        fillOpacity: 1,
      });
      marker.on("mouseover", function (e) {
        e.target.setStyle({
          fillColor: "green",
        });
      });
      marker.on("mouseout", function (e) {
        e.target.setStyle({
          fillColor: "#f03",
        });
      });
      marker.on("click", function (e) {
        setSelectedProperty(listing);
        setShowModal(true);
      });
      marker.bindTooltip(
        "<b>" +
          listing.sub_type +
          "</b><br>" +
          listing.price +
          " /-<br>" +
          listing.size +
          " " +
          listing.unit
      );

      markers.push(marker);
      marker.addTo(map);
    });
  };

  return (
    <>
      {showModal && selectedProperty && (
        <PropertiesModal
          properties={selectedProperty}
          show={showModal}
          setShow={setShowModal}
        />
      )}
    </>
  );
};

export default CustomerPropertyListing;
