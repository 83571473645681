function geoServerApi() {
  const baseUrl = "https://amlaaks.com/geoserver/islootech/wms";

  return {
    baseUrl: baseUrl,
    // layers: "islootech:cities,islootech:societies,islootech:blocks,islootech:plots",
    cityLayer: "islootech:cities",
    societyLayer: "islootech:societies",
    blockLayer: "islootech:blocks",
    plotLayer: "islootech:plots",
  };
}

export default geoServerApi();
