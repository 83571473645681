import { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { propertyActions } from "config/enums/propertyActions";
import PropertiesModal from "components/properties/PropertiesModal";

const PropertyListing = (props) => {
  const { propertyListing, filters, centeroid } = props;
  const map = useMap();
  const [openSingleProperty, setOpenSingleProperty] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState();

  // used useState because it was showing error on simple variable
  const [markers, setMarkers] = useState([]);
  const [tooltips, setTooltips] = useState([]);

  useEffect(() => {
    removeMarkers();
    addMarkers();
  }, [propertyListing]);

  // Display tooltip of the marker on hover of the listing
  useEffect(() => {
    if (centeroid && markers) {
      const marker = markers.filter((marker) => {
        const markerLat = marker._latlng.lat;
        const markerLng = marker._latlng.lng;

        return markerLat == centeroid.lat && markerLng == centeroid.lng;
      });

      if (marker.length > 0) {
        marker[0].openTooltip();
      }
      return () => {
        if (marker.length > 0) {
          marker[0].closeTooltip();
        }
      };
    }
  }, [centeroid]);

  const removeMarkers = () => {
    while (markers.length > 0) {
      let marker = markers.pop();
      map.removeLayer(marker);
    }
    while (tooltips.length > 0) {
      let tooltip = tooltips.pop();
      map.removeLayer(tooltip);
    }
  };

  var propertyImage = "";
  let pinColor = "";

  const addMarkers = () => {
    propertyListing?.forEach((listingArray) => {
      const allListings = Object.values(listingArray)[0]; // All properties at a single Lat, Lng position
      const listing = allListings[0]; // Get the first property

      if (listing?.image_url) {
        propertyImage = listing.image_url;
      } else {
        propertyImage = "property.jpg";
      }
      if (filters.action == propertyActions.Rent) {
        pinColor = "blue";
      } else if (filters.action == propertyActions.Sale) {
        pinColor = "#f03";
      } else if (filters.action == propertyActions.Sold) {
        pinColor = "#FFC300";
      }
      let marker = new L.circleMarker([listing.lat, listing.lng], {
        radius: 7,
        fillColor: pinColor,
        color: "white",
        fillOpacity: 1,
      });
      marker.on("mouseover", function (e) {
        e.target.setStyle({
          fillColor: "green",
        });
      });
      marker.on("mouseout", function (e) {
        e.target.setStyle({
          fillColor: pinColor,
        });
      });
      marker.on("click", function (e) {
        setSelectedProperty(allListings);
        setOpenSingleProperty(true);
      });
      marker.bindTooltip(
        '<img class= "pb-2" src="' +
          propertyImage +
          '" alt="Img" width="150px" height="100px" loading="lazy" /><br>' +
          "<b>" +
          listing.sub_type +
          "</b><br>" +
          listing.price +
          " /-<br>" +
          listing.size +
          " " +
          listing.unit
      );

      if (allListings?.length > 1) {
        var text = L.tooltip({
          permanent: true,
          direction: "center",
          className: "marker_tooltip",
        })
          .setContent(`${allListings.length}`)
          .setLatLng(marker.getLatLng());

        text.addTo(map);
        tooltips.push(text);
      }

      markers.push(marker);
      marker.addTo(map);
    });
  };

  return (
    <>
      {openSingleProperty && (
        <PropertiesModal
          show={openSingleProperty}
          setShow={setOpenSingleProperty}
          properties={selectedProperty}
        />
      )}
    </>
  );
};

export default PropertyListing;
