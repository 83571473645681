// These are the keys used for react query. All keys
// are written here so that they don't need to remember
function queryKeys() {
    return {
      userProfile: "userProfile",
      property: {
        metaData: "metaData",
        listing: "listing",
        detail: "detail",
      },
      customer:{
        property: {
          listing: "CustomerPropertyListing"
        }
      }
    };
  }
  
  export default queryKeys();