import React, {useEffect} from 'react'
import { useMap } from 'react-leaflet';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/dist/geosearch.css';

function Search() {
    const provider = new OpenStreetMapProvider({
        params:{
            countrycodes: 'pk',
        }
    });

    const searchControl = new GeoSearchControl({
        provider: provider,
        style: 'bar'
    });

    const map = useMap();
    useEffect(() => {
        map.addControl(searchControl);
        return () => map.removeControl(searchControl);
    }, []);

    return null;
}

export default Search
