import { useQuery } from "react-query";
import { axiosLaravel } from "config/axiosLaravel";
import laravelApi from "config/laravelApi";
import queryKeys from "config/queryKeys";

function useUserProfile(token) {
  async function getUserProfile() {
    if(token){
      let user = null;
      await axiosLaravel
        .get(laravelApi.user.profile)
        .then((result) => (user = result.data));
      return user;
    } else {
      return null;
    }
  }

  return useQuery(queryKeys.userProfile, getUserProfile, {
    retry: false,
  });
}

export default useUserProfile;
