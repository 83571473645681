import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { Button, Modal, Form, Row, Col, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { UserRoles } from "config/enums/userRoles";
import useSignup from "hooks/laravel/auth/useSignup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// TODO add other fields in the schema. At this time, only below fields are added in the form
const schema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().required(),
    confirm_password: yup.string().required(),
    mobile: yup.number(),
  })
  .required();

function Signup(props) {
  const { open, setOpen } = props;
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const signupQuery = useSignup();
  const [image, setImage] = useState(null);
  const formRef = useRef();
  const [loading, setLoading] = useState(false);

  const signup = (data) => {
    data = Object.entries(data);
    data.role_id = UserRoles.Customer;
    const formData = new FormData();
    data.forEach((element) => {
      formData.append(element[0], element[1]);
    });

    formData.append("role_id", UserRoles.Customer);
    if (image) {
      formData.append("image", image);
    }

    signupQuery.mutate(formData);
  };

  useEffect(() => {
    setLoading(signupQuery.isLoading);
  }, [signupQuery.isLoading]);

  useEffect(() => {
    if (signupQuery.isSuccess) {
      const data = signupQuery.data;
      toast.success(
        "Signup Successful! You can now login with your email and password!"
      );
      setOpen(false);
    } else if (signupQuery.isError) {
      toast.error(signupQuery.error.response.data?.message);
    }
  }, [signupQuery.isSuccess, signupQuery.isError]);

  return (
    <>
      <Modal
        show={open}
        onHide={() => setOpen(false)}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header
          closeButton
          className="mb-4"
          style={{ borderBottom: "none" }}
        >
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="Sign_amlaak"
          >
            Welcome To Amlaak
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(signup)} ref={formRef}>
            <div className="row">
              <Form.Group as={Col}>
                <Form.Label className="E_P_label">Profile Picture</Form.Label>
                <Form.Control
                  className="input_login"
                  placeholder="Upload Image"
                  type="file"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                  }}
                />
                {/* <span className="small text-danger">
                  {errors.image?.message}
                </span> */}
              </Form.Group>
            </div>

            <Row className="mb-3 mt-4">
              <Form.Group as={Col}>
                <Form.Label className="E_P_label">Name</Form.Label>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Control
                      className="input_login"
                      placeholder="Enter your name"
                      {...field}
                    />
                  )}
                />
                <span className="small text-danger">
                  {errors.name?.message}
                </span>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label className="E_P_label">Email</Form.Label>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Control
                      className="input_login"
                      placeholder="Enter your email"
                      {...field}
                    />
                  )}
                />
                <span className="small text-danger">
                  {errors.email?.message}
                </span>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className="E_P_label">Password</Form.Label>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Control
                      type="password"
                      className="input_login"
                      placeholder="Enter Password"
                      {...field}
                    />
                  )}
                />
                <span className="small text-danger">
                  {errors.password?.message}
                </span>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label className="E_P_label">Confirm Password</Form.Label>
                <Controller
                  name="confirm_password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Control
                      type="password"
                      className="input_login"
                      placeholder="Confirm Password"
                      {...field}
                    />
                  )}
                />
                <span className="small text-danger">
                  {errors.confirm_password?.message}
                </span>
              </Form.Group>
            </Row>
            {/* //TODO Uncomment when needed */}
            {/* <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className="E_P_label">Mobile No</Form.Label>
                <Controller
                  name="mobile"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      className="input_login"
                      placeholder="Mobile Number"
                      {...field}
                    />
                  )}
                />
                <span className="small text-danger">
                  {errors.mobile?.message}
                </span>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="E_P_label">Phone</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Phone Number"
                  className="input_login"
                />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3">
              <Form.Label className="E_P_label">Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Your Address"
                className="input_login"
              />
            </Form.Group>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className="E_P_label">City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="City"
                  className="input_login"
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label className="E_P_label">Province</Form.Label>
                <Form.Select defaultValue="Choose..." className="input_login">
                  <option>Choose...</option>
                  <option>Balochistan</option>
                  <option>KPK</option>
                  <option>Punjab</option>
                  <option>Sindh</option>
                  <option>Islamabad</option>
                  <option>AJK</option>
                  <option>Gilgit Baltistan</option>
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label className="E_P_label">Country</Form.Label>
                <Form.Select defaultValue="Pakistan" className="input_login">
                  <option>Pakistan</option>
                </Form.Select>
              </Form.Group>
            </Row> */}
            <Button
              className="w-100 Sign_in_btn"
              style={{ fontWeight: "bold" }}
              type="submit"
              disabled={loading}
            >
              Submit{" "}
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default Signup;
