import React, { useRef } from "react";
import {
  Form,
  ToggleButton,
  ToggleButtonGroup,
  InputGroup,
  FormControl,
  Modal,
  Spinner,
} from "react-bootstrap";
import { useState, useEffect } from "react";
import useMetaData from "hooks/laravel/property/useMetaData";
import useAddProperty from "hooks/laravel/property/useAddProperty";
import { propertyActions } from "config/enums/propertyActions";
import { toast } from "react-toastify";
import { Dropzone, FileItem } from "@dropzone-ui/react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import compressImage from "components/utils/compressImage";
import RichEditor from "components/utils/RichEditor";

const schema = yup.object({
  sub_type_id: yup
    .number()
    .required("Please select valid property sub-type!")
    .min(1, "Please select valid property sub-type!"),
  size: yup.number().required().min(1),
  unit_id: yup
    .number()
    .required("unit is required!")
    .min(1, "Please select valid unit!"),
  price: yup.number().required().min(1, "Please enter valid price!"),
});

function AddPropertyForm(props) {
  const { open, setOpen, plot, centeroid, onClose, marker } = props;
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const plotType = plot.landuse;
  const [images, setImages] = useState([]);

  const [editorLoaded, setEditorLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [mutationLoading, setMutationLoading] = useState(false);

  const [description, setDescription] = useState("");

  // Meta Data contains types, sub types and units
  const metaDataQuery = useMetaData();

  const [types, setTypes] = useState();
  const [units, setUnits] = useState();
  const [subTypes, setSubTypes] = useState();
  const [selectedType, setSelectedType] = useState();
  const addPropertyQuery = useAddProperty();
  const [formKey, setFormKey] = useState("form-key");
  const [selectedUnitId, setSelectedUnitId] = useState();
  const [plotSize, setPlotSize] = useState();

  const [selectedAction, setSelectedAction] = useState(propertyActions.Sale);
  const form = useRef();

  const updateImages = (incomingImages) => {
    setImages(incomingImages);
  };

  const removeImage = (id) => {
    setImages(images.filter((image) => image.id !== id));
  };

  useEffect(() => {
    const typeId = selectedType;
    if (typeId) {
      const type = types.filter((type) => type.id == typeId)[0]; // Get the first type matched
      setSubTypes(type.sub_types);
    }
  }, [selectedType, types]);

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  const submitForm = (data) => {
    data = Object.entries(data);

    const formData = new FormData();
    data.forEach((element) => {
      formData.append(element[0], element[1]);
    });

    formData.append("action", selectedAction);
    formData.append("lat", centeroid.lat);
    formData.append("lng", centeroid.lng);
    formData.append("description", description);
    formData.append("plot_id", plot.id);
    formData.append("type_id", selectedType);
    let markerJson = JSON.stringify(marker.toGeoJSON());
    formData.append("marker", markerJson);

    images?.forEach(async (image) => {
      const imageFile = image.file;
      const compressedImage = await compressImage(imageFile);
      formData.append("images[]", compressedImage);
    });

    // Applied timeout to finish compression before form submission
    setTimeout(() => {
      addPropertyQuery.mutate(formData);
    }, 2000);
    setMutationLoading(true);
  };

  useEffect(() => {
    if (metaDataQuery.isSuccess) {
      const propertyTypes = metaDataQuery.data.types;
      setTypes(metaDataQuery.data.types);
      setUnits(metaDataQuery.data.units);

      let type = propertyTypes.filter((type) => type.name == plotType);
      type = type[0]?.id;
      setSelectedType(type);

      let unitName = null;
      if (plot.marla > 0) {
        unitName = "marla";
      } else if (plot.kanal > 0) {
        unitName = "kanal";
      } else if (plot?.acer > 0) {
        unitName = "acer";
      }

      if (unitName && units) {
        const unitId = units?.filter((unit) => {
          return unit.name.toLowerCase() == unitName;
        })[0]?.id;
        setSelectedUnitId(unitId);
        setPlotSize(plot[unitName]);

        // Assign these fetched values to form
        const formValues = {
          unit_id: unitId,
          size: plot[unitName],
        };
        reset(formValues);
      }

      setLoading(false);
    }
  }, [metaDataQuery.data, metaDataQuery.isSuccess, units]);

  useEffect(() => {
    if (addPropertyQuery.isSuccess) {
      const message = addPropertyQuery.data?.message;
      const messageType = addPropertyQuery.data?.message_type;
      if (messageType == "success") {
        toast.success(message);
        setSubTypes(null);
        onClose();
        setOpen(false);
      } else if (messageType == "error") {
        toast.error(message);
      }
    } else if (addPropertyQuery.isError) {
      toast.error(
        "Error occurred while saving! Check your data and try again!"
      );
    }
  }, [addPropertyQuery.isSuccess, addPropertyQuery.isError]);

  useEffect(()=>{
    setMutationLoading(addPropertyQuery.isLoading);
  },[addPropertyQuery.isLoading])

  return (
    <>
      <Modal
        show={open}
        onHide={() => {
          setOpen(false);
          onClose();
        }}
        size="lg"
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: "none" }}
        ></Modal.Header>
        <Modal.Body>
          {loading ? (
            <p>loading...</p>
          ) : (
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="h5 font-light heading">
                    Enter property details to add your property
                  </div>

                  <div>Plot Number: {plot.plot_no}</div>
                  <div>Society: {plot.society}</div>
                  <div>Plot Type: {plot.plot_type}</div>
                  <br />

                  <form
                    ref={form}
                    key={formKey}
                    onSubmit={handleSubmit(submitForm)}
                  >
                    <div className="row">
                      <div className="col-12">
                        <ToggleButtonGroup
                          type="radio"
                          name="action"
                          defaultValue={propertyActions.Sale}
                          className="asfg"
                          onChange={(value) => {
                            setSelectedAction(value);
                          }}
                        >
                          <ToggleButton
                            variant=""
                            id="tbg-radio-1"
                            className="rent_sale_btn"
                            value={propertyActions.Sale}
                          >
                            Sale
                          </ToggleButton>

                          <ToggleButton
                            variant=""
                            id="tbg-radio-3"
                            className="rent_sale_btn"
                            value={propertyActions.Rent}
                          >
                            Rent
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="h5 font-light heading">
                        Type of property
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="mb-3">
                          {types &&
                            types.map((type) => (
                              <Form.Check
                                inline
                                key={type.id}
                                label={type.name}
                                checked={type.id == selectedType}
                                type="radio"
                                value={type.id}
                                onChange={(event) => {
                                  setSelectedType(event.target.value);
                                }}
                              />
                            ))}
                        </div>

                        <Controller
                          name="sub_type_id"
                          control={control}
                          render={({ field }) => (
                            <Form.Select {...field}>
                              <option key={-1} value={-1}>
                                Select Subtype
                              </option>
                              {subTypes &&
                                subTypes.map((subType) => (
                                  <option key={subType.id} value={subType.id}>
                                    {subType.name}
                                  </option>
                                ))}
                            </Form.Select>
                          )}
                        />
                        <span className="small text-danger">
                          {errors.sub_type_id?.message}
                        </span>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12"></div>
                    </div>
                    <hr className="mt-5" />
                    <div className="row">
                      <div className="h5 font-light heading">
                        Add Property Info for Better Results
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <InputGroup className="mb-3 mt-5">
                          <Controller
                            name="size"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <FormControl
                                defaultValue={plotSize}
                                className="size_text"
                                placeholder="Size"
                                {...field}
                              />
                            )}
                          />
                          <Controller
                            name="unit_id"
                            control={control}
                            render={({ field }) => (
                              <Form.Select
                                {...field}
                                defaultValue={selectedUnitId}
                              >
                                <option key={-1} value={-1}>
                                  Select Unit
                                </option>
                                {units &&
                                  units.map((unit) => (
                                    <option key={unit.id} value={unit.id}>
                                      {unit.name}
                                    </option>
                                  ))}
                              </Form.Select>
                            )}
                          />
                        </InputGroup>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <Controller
                          name="price"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <Form.Control
                              type="number"
                              placeholder="Price in (PKR)"
                              className="Prize_pkr mt-5"
                              {...field}
                            />
                          )}
                        />
                      </div>
                      <span className="small text-danger">
                        {errors.price?.message}
                      </span>
                      <span className="small text-danger">
                        {errors.unit_id?.message}
                      </span>
                      <span className="small text-danger">
                        {errors.size?.message}
                      </span>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <RichEditor
                          onChange={(data) => {
                            setDescription(data);
                          }}
                        />
                        <br />

                        {/* upload photo */}
                        <Dropzone
                          onChange={updateImages}
                          value={images}
                          accept="image/*"
                          label="Drop images of your property"
                          maxFiles={10}
                          maxFileSize={5000000}
                          footer={false}
                        >
                          {images.map((image, i) => {
                            return (
                              <FileItem
                                key={i}
                                {...image}
                                onDelete={removeImage}
                                preview
                              />
                            );
                          })}
                        </Dropzone>
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn Submit_btn mb-2 mt-5"
                        >
                          Submit {" "}
                          {mutationLoading && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddPropertyForm;
