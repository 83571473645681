import imageCompression from "browser-image-compression";

const compressImage = async (image) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  try {
    const compressedFile = await imageCompression(image, options);
    var file = new File([compressedFile], image.name);
    return file;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export default compressImage;
