import React from "react";
import { Button, Dropdown, Form, Modal, Tab, Tabs } from "react-bootstrap";
import { propertyActions } from "config/enums/propertyActions";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { FaAngleDown } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import useMetaData from "hooks/laravel/property/useMetaData";
import MultiRangeSlider from "components/utils/multiRangeSlider";

const minPriceRange = 1000;
const maxPriceRange = 500000000;

const FilterNavBar = (props) => {
  const { setFilters } = props;
  const [action, setAction] = useState(propertyActions.Sale);
  const [minPrice, setMinPrice] = useState();
  const [maxPrice, setMaxPrice] = useState();
  const [minSize, setMinSize] = useState();
  const [maxSize, setMaxSize] = useState();
  const [selectedUnit, setSelectedUnit] = useState();

  const minPriceRef = useRef();
  const maxPriceRef = useRef();
  const minSizeRef = useRef();
  const maxSizeRef = useRef();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showPriceDropdown, setShowPriceDropdown] = useState(false);
  const [showSizeDropdown, setShowSizeDropdown] = useState(false);
  const [units, setUnits] = useState();
  const [propertyTypes, setPropertyTypes] = useState();
  const [selectedType, setSelectedType] = useState();
  const [selectedSubType, setSelectedSubType] = useState();
  const [unitOptions, setUnitOptions] = useState();
  const selectedUnitRef = useRef();

  const metaDataQuery = useMetaData();

  const filterByPrice = () => {
    setMinPrice(minPriceRef.current.value);
    setMaxPrice(maxPriceRef.current.value);
  };

  const filterByType = (type, subType) => {
    setSelectedType(type);
    setSelectedSubType(subType);
  };

  const filterBySize = () => {
    setMaxSize(maxSizeRef.current.value);
    setMinSize(minSizeRef.current.value);
    setSelectedUnit(selectedUnitRef.current.value);
  };

  function filterByAction(e) {
    let value = e.target.value;
    setAction(value);
  }

  useEffect(() => {
    const localUnits = metaDataQuery.data?.units;
    if (localUnits) {
      setUnits(localUnits);
    }
    const localTypes = metaDataQuery.data?.types;
    if (localTypes) {
      setPropertyTypes(localTypes);
    }
  }, [metaDataQuery.data]);

  useEffect(() => {
    if (units) {
      const localUnitOptions = [];
      units.forEach((unit) => {
        let obj = {
          value: unit.id,
          label: unit.name,
        };
        localUnitOptions.push(obj);
      });
      setUnitOptions(localUnitOptions);
    }
  }, [units]);

  useEffect(() => {
    let filters = {};
    if (action) {
      filters.action = action;
    }
    if (minPrice) {
      filters.min_price = minPrice;
    }
    if (maxPrice) {
      filters.max_price = maxPrice;
    }
    if (selectedSubType) {
      filters.sub_type_id = selectedSubType.id;
    }

    if (selectedUnit && minSize) {
      filters.unit_id = selectedUnit;
      filters.min_size = minSize;
    }

    if (selectedUnit && maxSize) {
      filters.unit_id = selectedUnit;
      filters.max_size = maxSize;
    }

    if (filters) {
      filters.page = 1;
      setFilters(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    action,
    maxPrice,
    minPrice,
    selectedSubType,
    selectedUnit,
    minSize,
    maxSize,
  ]);

  return (
    <div className="header">
      <hr />
      <div className="container-fluid header_houserent">
        <div className="row d-block houserent_row">
          <ul className="d-flex house_rent_user_list">
            <li className="d-block front_most">
              <Dropdown style={{ display: "inline" }}>
                <Dropdown.Toggle
                  className="House_ font_col_size"
                  id="dropdown-basic"
                >
                  House <FaAngleDown />
                </Dropdown.Toggle>

                <Dropdown.Menu className="house_dropdown_houserent">
                  <Tabs
                    defaultActiveKey="Residential"
                    id="uncontrolled-tab-example"
                    className="mb-3 tab_house_dropdown"
                  >
                    {propertyTypes?.map((type) => (
                      <Tab eventKey={type.id} title={type.name} key={type.id}>
                        <div className="row row-cols-1 row-cols-md-2  House_row">
                          {type?.sub_types.map((subType) => (
                            <div className="col" key={subType.id}>
                              <Dropdown.Item
                                href="#"
                                active={selectedSubType?.id == subType.id}
                                onClick={() => {
                                  filterByType(type, subType);
                                }}
                                className="dropdown_all_btn_house"
                              >
                                {subType.name}
                              </Dropdown.Item>
                            </div>
                          ))}
                        </div>
                      </Tab>
                    ))}
                  </Tabs>
                  <Button
                    onClick={() => {
                      setSelectedSubType(null);
                    }}
                    className="price_heading_modal_ineer reset"
                  >
                    Reset
                  </Button>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="d-block front_most">
              <Form.Select
                aria-label="Default select example"
                className="House_ font_col_size"
                id="angle_rent"
                onChange={(e) => filterByAction(e)}
              >
                <option
                  key={propertyActions.Sale}
                  value={propertyActions.Sale}
                  className="text-dark"
                >
                  For Sale
                </option>
                <option
                  key={propertyActions.Rent}
                  value={propertyActions.Rent}
                  className="text-dark"
                >
                  For Rent
                </option>
                <option
                  key={propertyActions.Sold}
                  value={propertyActions.Sold}
                  className="text-dark"
                >
                  Sold
                </option>
              </Form.Select>
            </li>
            <li className="d-block front_most">
              <Dropdown
                show={showPriceDropdown}
                className="dropdown_btn_houserent_header"
                id="price_range_mobile"
              >
                <Dropdown.Toggle
                  onClick={() => {
                    setShowPriceDropdown(!showPriceDropdown);
                  }}
                  className="price_range_btn_ font_col_size"
                  id="dropdown-basic"
                >
                  Price Range <FaAngleDown />
                </Dropdown.Toggle>

                <Dropdown.Menu className="pricerang_dropdown_houserent">
                  <Form.Label className="Price_label m-0 ">
                    {" "}
                    <h3> Price Range</h3>{" "}
                  </Form.Label>
                  <MultiRangeSlider
                    min={minPriceRange}
                    max={maxPriceRange}
                    onChange={({ min, max }) => {
                      minPriceRef.current.value = min;
                      maxPriceRef.current.value = max;
                    }}
                  />
                  <div className="style_range_slider_form_wrapper__2AXhS d-flex">
                    <div className="style_range_slider_field__u9Co9">
                      <label>Min</label>
                      <Form.Control
                        type="number"
                        defaultValue={0}
                        ref={minPriceRef}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div
                      className="style_range_slider_field__u9Co9"
                      style={{ marginLeft: "30px" }}
                    >
                      <label>Max</label>
                      <Form.Control
                        type="number"
                        defaultValue={100}
                        ref={maxPriceRef}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <Button
                    onClick={() => {
                      setShowPriceDropdown(false);
                      filterByPrice();
                    }}
                    className="price_heading_modal_ineer"
                  >
                    Done
                  </Button>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="d-block front_most">
              <Dropdown
                className="dropdown_btn_houserent_header"
                show={showSizeDropdown}
                id="size_range_mobile"
              >
                <Dropdown.Toggle
                  className="price_range_btn_ font_col_size"
                  id="dropdown-basic"
                  onClick={() => {
                    setShowSizeDropdown(!showSizeDropdown);
                  }}
                >
                  Size Range <FaAngleDown />
                </Dropdown.Toggle>

                <Dropdown.Menu className="pricerang_dropdown_houserent">
                  <Form.Label className="Price_label m-0">
                    {" "}
                    <h3> Size Range</h3>{" "}
                  </Form.Label>

                  <Dropdown style={{ marginLeft: "7px" }}>
                    <span>Area Unit :</span>
                    <Form.Select
                      aria-label="Default select example"
                      className="marla_area_unit"
                      ref={selectedUnitRef}
                    >
                      <FaAngleDown className="angledown_for_all_btn" />
                      {unitOptions?.map((option) => (
                        <option
                          key={option.value}
                          className="text-dark"
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Dropdown>
                  <div className="mt-3">
                  <MultiRangeSlider
                    min={1}
                    max={1000}
                    onChange={({ min, max }) => {
                      console.log(`min = ${min}, max = ${max}`);
                      minSizeRef.current.value = min;
                      maxSizeRef.current.value = max;
                    }}
                  />
                    </div>

                  <div className="style_range_slider_form_wrapper__2AXhS d-flex">
                    <div className="style_range_slider_field__u9Co9">
                      <label>Min</label>
                      <Form.Control
                        type="number"
                        defaultValue={0}
                        ref={minSizeRef}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div
                      className="style_range_slider_field__u9Co9"
                      style={{ marginLeft: "30px" }}
                    >
                      <label>Max</label>
                      <Form.Control
                        type="number"
                        ref={maxSizeRef}
                        defaultValue={100}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <Button
                    className="price_heading_modal_ineer"
                    onClick={() => {
                      filterBySize();
                      setShowSizeDropdown(false);
                    }}
                  >
                    Done
                  </Button>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="d-block front_most">
              <div
                id="menu_range_mobile"
                style={{ display: "inline", marginLeft: "0px" }}
              >
                <Button className="price_rang_btn " onClick={handleShow}>
                  {" "}
                  More <FiMenu />
                </Button>
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Woohoo, you're reading this text in a modal!
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FilterNavBar;
