function laravelApi() {
  // const home = "http://localhost/amlaak/public/api";
  const home = "https://amlaaks.com/amlaak-backend/public/api";

  return {
    home: home,
    user: {
      profile: home + "/user-profile",
    },
    auth: {
      login: home + "/login",
      register: home + "/register",
      logout: home + "/logout",
    },
    property: {
      metaData: home + "/property/meta-data",
      listing: home + "/property/listing",
      detail: home + "/property/detail",
    },
    customer: {
      property: {
        listing: home + "/customer/property/listing",
        create: home + "/customer/property/create",
        update: home + "/customer/property/update",
        delete: home + "/customer/property/delete",

        addImage: home + "/customer/property/image/create",
        deleteImage: home + "/customer/property/image/delete",
      },
    },
  };
}

export default laravelApi();
