import React, { useEffect, useState } from "react";
import useDeleteProperty from "hooks/laravel/property/useDeleteProperty";
import { Pagination, Table } from "react-bootstrap";
import { FiEdit, FiEye, FiTrash } from "react-icons/fi";
import Swal from "sweetalert2";
import { usePagination, useTable } from "react-table";
import EditPropertyForm from "components/properties/EditPropertyForm";
import PropertiesModal from "components/properties/PropertiesModal";

function PropertiesTable({ data, meta, filters, setFilters }) {
  const [openEditForm, setOpenEditForm] = useState(false);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [pageNumbers, setPageNumbers] = useState();
  const [selectedProperty, setSelectedProperty] = useState();
  const [showModal, setShowModal] = useState(false);

  // TODO manage pagination through backend
  const columns = React.useMemo(
    () => [
      {
        Header: "Sr. No",
        accessor: (row, i) => i,
      },
      {
        Header: "Action",
        accessor: "action",
      },
      {
        Header: "Price (Rs)",
        accessor: "price",
        Cell: ({ row: { original } }) => {
          return `${original.price}/-`;
        },
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Size",
        accessor: "size",
        Cell: ({ row: { original } }) => {
          return `${original.size} ${original.unit}`;
        },
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row: { original } }) => {
          return (
            <>
              <button
                className="btn"
                onClick={() => {
                  viewProperty(original);
                }}
              >
                <FiEye />
              </button>
              <button
                className="btn"
                onClick={() => {
                  editProperty(original);
                }}
              >
                <FiEdit />
              </button>
              <button
                onClick={() => {
                  deleteProperty(original);
                }}
                className="btn"
              >
                <FiTrash />
              </button>
            </>
          );
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  useEffect(()=>{
      // Page Numbers for pagination
      let pageNumbersArray = [];
      const currentPage = meta.current_page;
      const lastPage = meta.last_page;
      const maxLinks = 7;
      let page = currentPage;

      for (let i = 0; i <= maxLinks; i++) {
        pageNumbersArray.push(page);
        if (page === lastPage) {
          break;
        }
        page++;
      }
      setPageNumbers(pageNumbersArray);

  },[meta.current_page, meta.last_page])

  const deletePropertyQuery = useDeleteProperty();

  function editProperty(property) {
    setSelectedPropertyId(property.id);
    setOpenEditForm(true);
  }

  function viewProperty(property) {
    setSelectedProperty(property);
    setShowModal(true);
  }

  function deleteProperty(property) {
    const propertyId = property.id;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deletePropertyQuery.mutate(propertyId);
      }
    });
  }

  const changePageNumber = (page) => {
    setFilters({ ...filters, page: page });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };


  return (
    <>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i}>
              {headerGroup.headers.map((column, i) => (
                <th {...column.getHeaderProps()} key={i}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                  {/**All this mess just to display Sr. No in sequence */}
                <td>
                  {row.cells[0].render("Cell").props.value + meta.from}
                </td>
                <td>{row.cells[1].render("Cell")}</td>
                <td>{row.cells[2].render("Cell")}</td>
                <td>{row.cells[3].render("Cell")}</td>
                <td>{row.cells[4].render("Cell")}</td>
                <td>{row.cells[5].render("Cell")}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
              <Pagination className="mt-3 justify-content-center">
                <Pagination.First
                  onClick={() => {
                    const page = 1;
                    changePageNumber(page);
                  }}
                  disabled={meta?.current_page === 1}
                />
                <Pagination.Prev
                  onClick={() => {
                    const page = meta?.current_page - 1;
                    changePageNumber(page);
                  }}
                  disabled={meta?.current_page === 1}
                />
                {pageNumbers?.map((pageNumber, i) => (
                  <Pagination.Item
                    key={i}
                    onClick={() => {
                      changePageNumber(pageNumber);
                    }}
                    active={pageNumber === meta.current_page}
                  >
                    {pageNumber}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => {
                    const page = meta?.current_page + 1;
                    changePageNumber(page);
                  }}
                  disabled={meta?.last_page === meta?.current_page}
                />
                <Pagination.Last
                  onClick={() => {
                    const page = meta?.last_page;
                    changePageNumber(page);
                  }}
                  disabled={meta?.last_page === meta?.current_page}
                />
              </Pagination>

      {openEditForm && selectedPropertyId && (
        <>
          <EditPropertyForm
            open={openEditForm}
            setOpen={setOpenEditForm}
            propertyId={selectedPropertyId}
          />
        </>
      )}
      {showModal && selectedProperty && (
        <PropertiesModal
          properties={selectedProperty}
          show={showModal}
          setShow={setShowModal}
        />
      )}
    </>
  );
}

export default PropertiesTable;
