import React, { useState, useEffect, useContext } from "react";
import {
  MapContainer,
  TileLayer,
  LayersControl,
  WMSTileLayer,
} from "react-leaflet";
import Search from "components/properties/Search";
import "leaflet/dist/leaflet.css";
// Used these to fix the error of markers not showing
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "leaflet-defaulticon-compatibility";

import CurrentLocation from "components/properties/CurrentLocation";
import { FaRegDotCircle } from "react-icons/fa";
import { IconContext } from "react-icons";
import geoServerApi from "config/geoServerApi";
import useFeatureInfo from "hooks/geo/useFeatureInfo";
import L from "leaflet";
import { AuthContext } from "context/AuthContext";
import PropertyListing from "components/properties/PropertyListing";
import { mapConfig } from "config/mapConfig";

function Map(props) {
  const {
    propertyListing,
    centeroid,
    filters,
    setFilters,
    setCenteroid,
    zoom,
    setZoom,
  } = props;
  const minZoom = 1;
  const maxZoom = 22;

  // Inside B-17
  const [center, setCenter] = useState({ lat: 33.684511, lon: 72.823963 });

  const [toggleLocate, setToggleLocate] = useState(false);
  const [loc, setLoc] = useState(false);
  const { mutate, data, isSuccess, isError } = useFeatureInfo();
  const [mapHook, setMapHook] = useState();
  const [mapBounds, setMapBounds] = useState(null);
  // const [selectedBounds, setSelectedBounds] = useState([]);
  let selectedBounds = []
  let mapMoving = false;
  const { token } = useContext(AuthContext);

  useEffect(() => {
    if (isSuccess && data) {
      const latlng = {
        lat: data.properties.lat,
        lng: data.properties.lng,
      };
      L.popup()
        .setLatLng(latlng)
        .setContent(
          "<b>Plot Id: </b>" +
            data.properties["id"] +
            "<br>" +
            "<b>City: </b>" +
            data.properties["city"] +
            "<br>" +
            "<b>Society: </b>" +
            data.properties["society"] +
            "<br>" +
            "<b>Block: </b>" +
            data.properties["block"] +
            "<br>"
        )
        .openOn(mapHook);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Contains map configurations needed on create
  const mapCreated = (map) => {
    map.on("click", function (e) {
      const centeroid = e.latlng;
      mutate({ centeroid, map });
    });

    map.on("movestart", function (e) {
      selectedBounds = [];
      mapMoving = true;
    });

    map.on("moveend", function (e) {
      let bounds = map.getBounds();
      bounds = {
        south_west: bounds._southWest,
        north_east: bounds._northEast,
      };
      selectedBounds.push(bounds);
      
      // Add two seconds delay before calling the API (through filters)
      setTimeout(() => {
        if (selectedBounds.length > 0 && !mapMoving) {
          let lastBound = selectedBounds.pop();
          selectedBounds = []; // Empty the array
          setMapBounds(lastBound);
        }
      }, 2000);
      mapMoving = false;
    });
    setMapHook(map);

    map.on("zoomend", function () {
      setZoom(map.getZoom());
    });
  };

  // useEffect(()=> {
  //   console.log(zoom);
  // },[zoom])

  useEffect(() => {
    if (mapBounds && !mapMoving) {
      setFilters({ ...filters, bounds: mapBounds, page: 1 });
    }
  }, [mapBounds]);

  return (
    <>
      <MapContainer
        center={center}
        minZoom={minZoom}
        maxZoom={maxZoom}
        zoom={12}
        whenCreated={(map) => {
          mapCreated(map);
        }}
        style={{ maxHeight: "74vh" }}
      >
        <div className="leaflet-bottom leaflet-left"></div>
        <LayersControl position="topright">
          <LayersControl.BaseLayer name="Satellite View">
            <TileLayer
              attribution={mapConfig.google.attribution}
              url={mapConfig.google.url}
              maxZoom={maxZoom}
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer checked name="Street View">
            <TileLayer
              attribution={mapConfig.openstreet.attribution}
              url={mapConfig.openstreet.url}
              maxZoom={maxZoom}
            />
          </LayersControl.BaseLayer>
          <LayersControl.Overlay checked name="City View">
            <WMSTileLayer
              url={geoServerApi.baseUrl}
              version="1.1.0"
              layers={geoServerApi.cityLayer}
              transparent="true"
              opacity="0.3"
              format="image/png"
              maxZoom={maxZoom}
            />
          </LayersControl.Overlay>

          <LayersControl.Overlay checked name="Society View">
            <WMSTileLayer
              url={geoServerApi.baseUrl}
              version="1.1.0"
              layers={geoServerApi.societyLayer}
              transparent="true"
              opacity="0.6"
              format="image/png"
              maxZoom={maxZoom}
            />
          </LayersControl.Overlay>

          <LayersControl.Overlay checked name="Block View">
            <WMSTileLayer
              url={geoServerApi.baseUrl}
              version="1.1.0"
              layers={geoServerApi.blockLayer}
              transparent="true"
              opacity="0.6"
              format="image/png"
              maxZoom={maxZoom}
            />
          </LayersControl.Overlay>

          <LayersControl.Overlay checked name="Plot View">
            <WMSTileLayer
              url={geoServerApi.baseUrl}
              version="1.1.0"
              layers={geoServerApi.plotLayer}
              transparent="true"
              opacity="0.7"
              format="image/png"
              maxZoom={maxZoom}
            />
          </LayersControl.Overlay>
        </LayersControl>
        <Search />
        {loc && <CurrentLocation toggle={toggleLocate} />}
        <IconContext.Provider
          value={{ style: { fontSize: "30px", color: "green" } }}
        >
          <div
            onClick={() => {
              setToggleLocate(!toggleLocate);
              setLoc(true);
            }}
            className="current-location-btn"
          >
            <FaRegDotCircle />
          </div>
        </IconContext.Provider>
        <PropertyListing
          centeroid={centeroid}
          propertyListing={propertyListing}
          filters={filters}
        />
      </MapContainer>
    </>
  );
}

export default Map;
