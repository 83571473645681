import { useMutation, useQueryClient } from "react-query";
import { axiosLaravel } from "config/axiosLaravel";
import laravelApi from "config/laravelApi";
import queryKeys from "config/queryKeys";

function useAddProperty() {
  const queryClient = useQueryClient();
  return useMutation(
    (property) =>
      axiosLaravel
        .post(laravelApi.customer.property.create, property)
        .then((result) => result.data),
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(queryKeys.customer.property.listing);
      },
    }
  );
}

export default useAddProperty;
