import { useMutation, useQueryClient } from "react-query";
import { axiosLaravel } from "config/axiosLaravel";
import laravelApi from "config/laravelApi";
import queryKeys from "config/queryKeys";

function useLogin() {
  const queryClient = useQueryClient();
  return useMutation(
    (user) => axiosLaravel.post(laravelApi.auth.login, user).then((result) => result.data),
    {
      onSuccess: () => {
        // Invalidate and refetch


      },
    }
  );
}

export default useLogin;
