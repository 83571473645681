import { useQuery } from "react-query";
import { axiosLaravel } from "config/axiosLaravel";
import laravelApi from "config/laravelApi";
import queryKeys from "config/queryKeys";

function usePropertyDetail(propertyId) {
  async function getPropertyDetail() {
    let data = null;
    await axiosLaravel
      .get(laravelApi.property.detail + `/${propertyId}`)
      .then((result) => (data = result.data.data));
    return data;
  }

  return useQuery([queryKeys.property.detail, propertyId], getPropertyDetail, {
    keepPreviousData: true,
  });
}

export default usePropertyDetail;
