import { Circle, FeatureGroup, useMap } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import L from "leaflet";
import red_pin from "assets/red-pin.png";
import useFeatureInfo from "hooks/geo/useFeatureInfo";
import { useEffect, useState } from "react";
import AddPropertyForm from "components/properties/AddPropertyForm";
import { toast } from "react-toastify";

function DropPin() {
  const map = useMap();
  const { mutate, data, isSuccess, isError } = useFeatureInfo();
  const [openPropertyForm, setOpenPropertyForm] = useState(false);
  const [plot, setPlot] = useState();
  const [centeroid, setCenteroid] = useState();
  const [currentMarker, setCurrentMarker] = useState();

  const customMarker = L.Icon.extend({
    options: {
      shadowUrl: null,
      iconAnchor: new L.Point(12, 12),
      iconSize: new L.Point(18, 18),
      iconUrl: red_pin,
    },
  });

  const onModalClose = () => {
    // Remove current marker when modal close
    removeMarker();
  };

  const removeMarker = () => {
    if (currentMarker) {
      map.removeLayer(currentMarker);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      const plot = data?.properties;

      if (plot) {
        setPlot(plot);
        if (plot.landuse == "Residential" || plot.landuse == "Commercial") {
          setOpenPropertyForm(true);
        } else {
          toast.error("This property cannot be listed for Sale or Rent.");
          removeMarker();
        }
      } else {
        toast.error("You cannot add property at this location.");
        removeMarker();
      }
    }
    if (isError) {
      toast.error("Error occurred while fetching details. Please try again!");
      removeMarker();
    }
  }, [data, isError, isSuccess]);

  useEffect(() => {
    if (plot) {
      const center = {
        lat: plot.lat,
        lng: plot.lng,
      };
      currentMarker.setLatLng(center);
    }
  }, [plot]);

  return (
    <>
      <FeatureGroup>
        <EditControl
          position="topleft"
          onCreated={(e) => onPinCreate(e, map)}
          onEdited={(e) => onPinEdit(e, map)}
          draw={{
            rectangle: false,
            polyline: false,
            polygon: false,
            circle: false,
            circlemarker: false,

            // marker: {
            //   icon: new customMarker(),
            // },
          }}
        />
        <Circle center={[51.51, -0.06]} radius={200} />
      </FeatureGroup>
      {openPropertyForm && (
        <AddPropertyForm
          open={openPropertyForm}
          setOpen={setOpenPropertyForm}
          onClose={onModalClose}
          plot={plot}
          marker={currentMarker}
          centeroid={centeroid}
        />
      )}
    </>
  );

  function onPinCreate(e, map) {
    setCurrentMarker(e.layer);
    // Get info data
    const centeroid = e.layer._latlng;
    setCenteroid(centeroid);
    mutate({ centeroid, map });
  }

  function onPinEdit(e, map) {
    const layers = e.layers;
    layers.eachLayer((layer) => {
      setCurrentMarker(layer);
      const centeroid = layer._latlng;
      setCenteroid(centeroid);
      mutate({ centeroid, map });
    });
  }
}

export default DropPin;
