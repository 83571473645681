import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { AuthContext } from "context/AuthContext";
import useLogin from "hooks/laravel/auth/useLogin";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

function Login(props) {
  const { open, setOpen, setOpenSignup } = props;
  const { loginUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const loginQuery = useLogin();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const login = (data) => {
    loginQuery.mutate(data);
  };

  useEffect(() => {
    if (loginQuery.isSuccess) {
      const data = loginQuery.data;
      loginUser(data.user, data.token);
      toast.success("Logged In Successfully!");
      setOpen(false);
    } else if (loginQuery.isError) {
      toast.error("Wrong email or password. Please try again!");
      //console.log(loginQuery.error.response.data);
    }
  }, [loginQuery.isSuccess, loginQuery.isError]);

  useEffect(() => {
    setLoading(loginQuery.isLoading);
  }, [loginQuery.isLoading]);

  return (
    <>
      <Modal
        show={open}
        onHide={() => setOpen(false)}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="Sign_amlaak"
          >
            Welcome To Amlaak
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="py-4 px-4 mt-3" onSubmit={handleSubmit(login)}>
            <Form.Group className="mb-3">
              <Form.Label className="E_P_label">Email</Form.Label>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Control
                    className="input_login"
                    placeholder="Enter email"
                    {...field}
                  />
                )}
              />
              <span className="small text-danger">{errors.email?.message}</span>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="E_P_label">Password</Form.Label>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Control
                    type="password"
                    className="input_login"
                    placeholder="Enter Password"
                    {...field}
                  />
                )}
              />
              <span className="small text-danger">
                {errors.password?.message}
              </span>
            </Form.Group>
            <Form.Group className=" mt-4">
              <Button
                variant=""
                type="submit"
                className="Sign_in_btn"
                disabled={loading}
              >
                Sign in{" "}
                {loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Button>
            </Form.Group>
            <Form.Group className="mt-1">
              <Button variant="" type="button" className="Forgot_pass">
                Forgot your Password?
              </Button>
            </Form.Group>
          </Form>

          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <div>
            Don't have account?
            <Button
              variant=""
              className="Sign_In"
              style={{
                fontWeight: "600",
              }}
              onClick={() => {
                setOpenSignup(true);
                setOpen(false);
              }}
            >
              Sign Up
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default Login;
