import { useQuery } from "react-query";
import { axiosLaravel } from "config/axiosLaravel";
import laravelApi from "config/laravelApi";
import queryKeys from "config/queryKeys";

function useCustomerPropertyListing(filters) {
  async function getPropertyListing() {
    let data = null;
    await axiosLaravel
      .get(laravelApi.customer.property.listing, { params: filters })
      .then((result) => (data = result.data?.data));
    return data;
  }

  return useQuery(
    [queryKeys.customer.property.listing, filters],
    getPropertyListing,
    { keepPreviousData: true, staleTime: 60000 }
  );
}

export default useCustomerPropertyListing;
