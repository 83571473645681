import { formatDistance, subDays } from "date-fns";
import usePropertyDetail from "hooks/laravel/property/usePropertyDetail";
import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { Button, Modal, Navbar, Nav, Carousel, Card } from "react-bootstrap";
import { BiHeart } from "react-icons/bi";
import { GoPrimitiveDot } from "react-icons/go";
import DOMPurify from 'dompurify'

function PropertiesModal(props) {
  const { show, setShow, properties } = props;
  const [loading, setLoading] = useState(true);

  let firstProperty = properties;
  let others = null;
  if (properties?.length >= 1) {
    firstProperty = properties[0];
    others = properties?.filter((property) => property.id != firstProperty.id);
  }

  const [propertyId, setPropertyId] = useState(firstProperty.id);
  const propertyQuery = usePropertyDetail(propertyId);

  // Main property is the property currently selected on the modal
  const [mainProperty, setMainProperty] = useState();

  const [otherProperties, setOtherProperties] = useState(others);
  // const currentDate = new Date();

  const [show_2, setShow_2] = useState(false);
  const handleClose = () => setShow_2(false);
  const handleShow = () => setShow_2(true);
  const [show_3, setShow_3] = useState(false);
  const handleClose_3 = () => setShow_3(false);
  const handleShow_3 = () => setShow_3(true);
  const [index, setIndex] = useState(0);
  const currentDate = new Date();

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    if (
      propertyQuery.isSuccess &&
      !propertyQuery.isFetching &&
      propertyQuery.data
    ) {
      setMainProperty(propertyQuery.data);
      setLoading(false);
    }
  }, [propertyQuery.data, propertyQuery.isFetching]);

  useEffect(() => {
    setLoading(true);
  }, [propertyId]);

  useEffect(() => {
    if (properties?.length > 1 && mainProperty) {
      let others = properties?.filter(
        (property) => property.id != mainProperty.id
      );
      setOtherProperties(others);
    }
  }, [mainProperty]);

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
          <Modal.Body className="p-0">
        {loading ? (
        <p>loading...</p>
        ): (
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-lg-7 col-md-7 col-sm-7 col-12 pr-0 mob_size_slider">
                  <div className="scroll_modal p-0">
                    {mainProperty.images?.length > 0 ? (
                      <>
                        {mainProperty.images?.map((image) => (
                          <img
                            key={image.id}
                            src={image.url}
                            alt=""
                            className="homemodal_img w-100"
                          />
                        ))}
                      </>
                    ) : (
                      <img
                        src="property.jpg"
                        alt=""
                        className="homemodal_img w-100"
                      />
                    )}
                  </div>
                  <Carousel variant="dark slider_only_mobile">
                    {mainProperty.images?.map((image) => (
                      <Carousel.Item key={image.id}>
                        <img src={image.url} alt="" className="d-block w-100" />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-5 col-12 padding_right">
                  <Modal.Header
                    closeButton
                    className="modal_header_single"
                  ></Modal.Header>
                  <span className="dollor">PKR {mainProperty.price}</span>
                  <span className="small_word">
                    <span className="five_5">6 </span>
                    bd
                  </span>
                  <span className="small_word_5ba">
                    <span className="five_5">5</span> ba
                  </span>
                  <span className="small_word">
                    <span className="five_5">
                      {mainProperty.size} {mainProperty.unit}{" "}
                    </span>
                  </span>

                  <p className="Rosedale">
                    {` ${mainProperty.sub_type}`} - {" "}
                    {mainProperty.plot.society}, {mainProperty.plot.city}
                  </p>
                  <>
                    <span className="dot_for_sale"></span>
                    <GoPrimitiveDot className="dot_icon" />
                    <span className="for_sale">
                      For {mainProperty.action_detail}
                      <a className="Zestimate" onClick={handleShow}>
                        Zestimate®:
                      </a>
                    </span>
                    <Modal show={show_2} onHide={handleClose} animation={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>What's a Zestimate?</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        The Zestimate is Amlaak best estimate of this home's
                        market value. It is not an appraisal and it should be
                        used as a starting point. Learn more.
                      </Modal.Body>
                    </Modal>
                    <span className="small_word"> None Help</span>
                  </>
                  <Button
                    variant="outline outline_button contact_agent mt-3"
                    onClick={handleShow_3}
                  >
                    Contact an Agent
                  </Button>
                  <Modal show={show_3} onHide={handleClose_3} animation={false}>
                    <Modal.Header closeButton>
                      <Modal.Title>What's a Zestimate?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      The Zestimate is Amlaak best estimate of this home's
                      market value. It is not an appraisal and it should be used
                      as a starting point. Learn more.
                    </Modal.Body>
                  </Modal>
                  <Button
                    variant="btn outline_button take_a_tour mt-3"
                    onClick={handleShow_3}
                  >
                    Take a Tour
                  </Button>
                  <hr className="horizontal_line_modal" />
                  <Modal show={show_3} onHide={handleClose_3} animation={false}>
                    <Modal.Header closeButton>
                      <Modal.Title>What's a Zestimate?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      The Zestimate is Amlaak best estimate of this home's
                      market value. It is not an appraisal and it should be used
                      as a starting point. Learn more.
                    </Modal.Body>
                  </Modal>
                  {/* <Carousel activeIndex={index} onSelect={handleSelect}>
                    <Carousel.Item>
                      <Navbar expand="lg" className="modal_nabar_single">
                        <Navbar.Collapse id="basic-navbar-nav">
                          <Nav className="me-auto" interval="clrInt">
                            <Nav.Link
                              href="#link"
                              className="small_navbar_links"
                            >
                              Overview
                            </Nav.Link>
                            <Nav.Link
                              href="#link"
                              className="small_navbar_links"
                            >
                              Facts and feature
                            </Nav.Link>
                            <Nav.Link
                              href="#link"
                              className="small_navbar_links"
                            >
                              Home value
                            </Nav.Link>
                            <Nav.Link
                              href="#link"
                              className="small_navbar_links"
                            >
                              Prices and tax history
                            </Nav.Link>
                          </Nav>
                        </Navbar.Collapse>
                      </Navbar>
                    </Carousel.Item>
                    <Carousel.Item>
                      <Navbar expand="lg" className="modal_nabar_single">
                        <Navbar.Collapse id="basic-navbar-nav">
                          <Nav className="me-auto">
                            <Nav.Link
                              href="#link"
                              className="small_navbar_links"
                            >
                              Monthly cost
                            </Nav.Link>
                            <Nav.Link
                              href="#link"
                              className="small_navbar_links"
                            >
                              Facts and feature
                            </Nav.Link>
                            <Nav.Link
                              href="#link"
                              className="small_navbar_links"
                            >
                              Home value
                            </Nav.Link>
                            <Nav.Link
                              href="#link"
                              className="small_navbar_links"
                            >
                              Prices and tax history
                            </Nav.Link>
                          </Nav>
                        </Navbar.Collapse>
                      </Navbar>
                    </Carousel.Item>
                  </Carousel> */}

                  <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(mainProperty.description)}}></div>
                  <p>
                    Upload Time:{" "}
                    {formatDistance(
                      subDays(new Date(mainProperty.created_at), 0),
                      currentDate,
                      { addSuffix: true }
                    )}
                  </p>
                  <p>
                    Address: {mainProperty.plot.plot_number}, Street{" "}
                    {mainProperty.plot.street}, Block {mainProperty.plot.block},{" "}
                    {mainProperty.plot.society}, {mainProperty.plot.city}
                  </p>
                  <p>Uploaded By: {mainProperty.listed_by}</p>

                  {otherProperties?.length > 0 && (
                    <div className="row justify-content-center">
                      <h6 className="my-3">
                        Other Properties At This Location
                      </h6>
                      {otherProperties?.map((property) => (
                        <Fragment key={property.id}>
                          <div className="col-10">
                            <div
                              onClick={() => {
                                setPropertyId(property.id);
                              }}
                              className="hole_card"
                            >
                              <Card className="house_rent_card mb-3">
                                <Card.Img
                                  variant="top"
                                  src={
                                    property?.image_url ||
                                    "../images/house_rent2.jpg"
                                  }
                                  loading="lazy"
                                  className="house_rent_card_img"
                                />
                                <div className="list-card-actions">
                                  <button
                                    className="list-card-save"
                                    type="button"
                                    aria-label="Save"
                                  >
                                    <svg>
                                      <BiHeart />
                                      <title>Save this home</title>
                                    </svg>
                                  </button>
                                </div>
                                <Card.Body>
                                  <Card.Title>PKR {property.price}</Card.Title>
                                    <div className="card_paragraph">
                                      <p>
                                        {property.size} {property.unit} -{" "}
                                        {property.sub_type} - For{" "}
                                        {property.action}
                                      </p>
                                    </div>
                                  <p className="Listing_by">
                                    LISTING BY: {property.listed_by}
                                  </p>
                                </Card.Body>
                              </Card>
                            </div>
                          </div>
                        </Fragment>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
        )}
          </Modal.Body>
      </Modal>
    </>
  );
}

export default PropertiesModal;
