import React, { useState } from "react";
import RichTextEditor from "react-rte";

const RichEditor = ({ onChange, value }) => {
    if (!value) {
      value = "";
    }

  const [editorValue, setEditorValue] = useState(
    RichTextEditor.createValueFromString(value, "html")
  );

  const handleChange = (value) => {
    setEditorValue(value);
    onChange(value.toString("html"));
  };
  return (
    <RichTextEditor value={editorValue} onChange={handleChange} multiline />
  );
};

export default RichEditor;
