import React, { useEffect } from "react";
import { useState } from "react";
import { Button, ButtonGroup, Pagination, Spinner } from "react-bootstrap";
import MapPrivate from "components/dashboard/MapPrivate";
import { propertyActions } from "config/enums/propertyActions";
import useCustomerPropertyListing from "hooks/laravel/property/useCustomerPropertyListing";
import { useLocation } from "react-router-dom";
import PropertiesTable from "./PropertiesTable";
import { toast } from "react-toastify";

function Dashboard() {
  const location = useLocation();
  const displayMap = location.state?.displayMap;

  const [propertyAction, setPropertyAction] = useState(propertyActions.Sale);
  const [filters, setFilters] = useState({ action: propertyAction });
  const listingQuery = useCustomerPropertyListing(filters);
  const [customerProperties, setCustomerProperties] = useState(null);
  const [markers, setMarkers] = useState();
  const [meta, setMeta] = useState(null);
  const [displayTable, setDisplayTable] = useState(displayMap ? false : true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (listingQuery.isSuccess && listingQuery.isFetched) {
      let properties = listingQuery.data?.properties;
      let markers = listingQuery.data?.markers;
      let meta = properties.meta;

      setCustomerProperties(properties);
      setMarkers(markers);
      setMeta(meta);
    }

  }, [listingQuery.data, filters, listingQuery.isFetching]);

  useEffect(() => {
    if (listingQuery.isError) {
      toast.error("Error occurred while fetching the properties.");
    }
  }, [listingQuery.isError]);


  useEffect(() => {
    setDisplayTable(displayMap ? false : true);
  }, [displayMap]);

  useEffect(() => {
    setLoading(listingQuery.isFetching);
  }, [listingQuery.isFetching]);

  return (
    <>
      <div className={`container ${loading && "disabled_parent"}`}>
        {loading && (
          <Spinner
            animation="grow"
            variant="primary"
            className="loading_spinner"
          />
        )}
        <div className="row mt-5">
          <div className="col-lg-3 col-md-3 col-sm-3 col-12">
            <ButtonGroup vertical>
              <Button className="vertical_btn">
                <img
                  src="images/active-listings.png"
                  alt=""
                  className="vertical_btn_img "
                />{" "}
                <span className="tabs_text">Active Listings</span>
              </Button>
              <Button className="vertical_btn">
                <img
                  src="images/waiting-approval.png"
                  alt=""
                  className="vertical_btn_img"
                />{" "}
                <span className="tabs_text">Waiting Approval</span>
              </Button>
              <Button className="vertical_btn">
                <img
                  src="images/closed-listings.png"
                  alt=""
                  className="vertical_btn_img"
                />{" "}
                <span className="tabs_text">Expired/Deleted</span>
              </Button>
              <Button className="vertical_btn">
                <img
                  src="images/sold.png"
                  alt=""
                  className="vertical_btn_img "
                />{" "}
                <span className="tabs_text">Sold/Rented</span>
              </Button>
            </ButtonGroup>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-8 col-12">
            <div className="row">
              <div className="col">
                <div className="mobile_size">
                  <h3>Active Listings</h3>
                  <Button
                    variant=""
                    className={`dashboard_sale_rent_btn`}
                    active={propertyAction == propertyActions.Sale}
                    onClick={() => {
                      setPropertyAction(propertyActions.Sale);
                      setFilters({
                        ...filters,
                        action: propertyActions.Sale,
                        page: 1,
                      });
                    }}
                  >
                    Sale
                  </Button>
                  <Button
                    variant=""
                    active={propertyAction == propertyActions.Rent}
                    className="dashboard_sale_rent_btn"
                    onClick={() => {
                      setPropertyAction(propertyActions.Rent);
                      setFilters({
                        ...filters,
                        action: propertyActions.Rent,
                        page: 1,
                      });
                    }}
                    style={{ marginLeft: "7px" }}
                  >
                    Rent
                  </Button>
                  <Button
                    onClick={() => {
                      setDisplayTable(!displayTable);
                    }}
                    className="mapview_btn"
                  >
                    {displayTable ? "Map View" : "Table View"}
                  </Button>
                  <hr className="horizontal_line" />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              {displayTable ? (
                <div className="col">
                  {customerProperties && (
                    <PropertiesTable
                      data={customerProperties?.data}
                      meta={customerProperties?.meta}
                      filters={filters}
                      setFilters={setFilters}
                    />
                  )}
                </div>
              ) : (
                <div className="col">
                  <MapPrivate customerProperties={markers} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
