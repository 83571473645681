import { useQuery } from "react-query";
import { axiosLaravel } from "config/axiosLaravel";
import laravelApi from "config/laravelApi";
import queryKeys from "config/queryKeys";

function useMetaData() {
  async function getMetaData() {
    let data = null;
    await axiosLaravel
      .get(laravelApi.property.metaData)
      .then((result) => (data = result.data.data));
    return data;
  }

  return useQuery(queryKeys.property.metaData, getMetaData, {
    staleTime: Infinity,
  });
}

export default useMetaData;
