import FilterNavBar from "components/properties/FilterNavBar";
import { propertyActions } from "config/enums/propertyActions";
import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Card, Button, Pagination, Spinner } from "react-bootstrap";
import { BiCategory, BiChevronRight, BiHeart } from "react-icons/bi";
import { BsArrowDownUp, BsMap } from "react-icons/bs";
import Map from "elements/Map";
import usePropertyListing from "hooks/laravel/property/usePropertyListing";
import { formatDistance, subDays } from "date-fns";
import PropertiesModal from "components/properties/PropertiesModal";
import { toast } from "react-toastify";

const Home = () => {
  const initialFilters = {
    action: propertyActions.Sale,
  };
  const [filters, setFilters] = useState(initialFilters);
  const listingQuery = usePropertyListing(filters);
  const [propertyListing, setPropertyListing] = useState(null);
  const [meta, setMeta] = useState();
  const [mapListing, setMapListing] = useState();
  const [showSingleProperty, setShowSingleProperty] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState();
  const [selectedSociety, setSelectedSociety] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [selectedAction, setSelectedAction] = useState();
  const [centeroid, setCenteroid] = useState();
  const [loading, setLoading] = useState(true);
  const [pageNumbers, setPageNumbers] = useState();
  const [zoom, setZoom] = useState();
  const listingRef = useRef();

  const [isSmallScreen, setIsSmallScreen] = useState(
    window.innerWidth <= 991.98 // Check if the screen size is small
  );
  const [toggleMap, setToggleMap] = useState(false);

  const currentDate = new Date();

  useEffect(() => {
    if (listingQuery.isSuccess && listingQuery.isFetched) {
      let properties = listingQuery.data?.properties;
      let markers = listingQuery.data?.markers;
      if (properties) {
        let meta = properties.meta;
        setMeta(meta);

        // Page Numbers for pagination
        let pageNumbersArray = [];
        const currentPage = meta.current_page;
        const lastPage = meta.last_page;
        const maxLinks = 7;
        let page = currentPage;

        for (let i = 0; i <= maxLinks; i++) {
          pageNumbersArray.push(page);
          if (page === lastPage) {
            break;
          }
          page++;
        }
        setPageNumbers(pageNumbersArray);

        properties = Object.values(properties.data); // convert to array
        if (properties.length > 0) {
          var firstProperty = Object.values(Object.values(properties)[0])[0][0];
          setSelectedSociety(firstProperty.society);
          setSelectedAction(firstProperty.action);
          setSelectedCity(firstProperty.city);
        }
        setPropertyListing(properties);
      }
      if (markers) {
        markers = Object.values(markers);
        setMapListing(markers);
      }
    }
  }, [listingQuery.data, filters, listingQuery.isFetching]);

  const changePageNumber = (page) => {
    setFilters({ ...filters, page: page });
    listingRef.current.scrollIntoView();
  };

  useEffect(() => {
    if (listingQuery.isError) {
      toast.error("Error occurred while fetching the properties.");
      setLoading(false);
    }
  }, [listingQuery.isError]);

  useEffect(() => {
    setLoading(listingQuery.isFetching);
  }, [listingQuery.isFetching]);

  return (
    <>
      <FilterNavBar setFilters={setFilters} />
      <div className={`container-fluid `}>
        <div className="button_group_mob text-center">
          <Button
            variant="primary"
            onClick={() => {
              setIsSmallScreen(true);
              setToggleMap(!toggleMap);
            }}
          >
            {toggleMap ? (
              <>
                <BiCategory /> View Listings
              </>
            ) : (
              <>
                <BsMap /> View Map
              </>
            )}
          </Button>
        </div>
        <div className="row mob_size_direction_col ">
          {(!isSmallScreen || toggleMap) && (
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 p-0 tablet_col">
              <Map
                propertyListing={mapListing}
                filters={filters}
                setFilters={setFilters}
                centeroid={centeroid}
                setCenteroid={setCenteroid}
                zoom={zoom}
                setZoom={setZoom}
              />
            </div>
          )}

          {(!isSmallScreen || !toggleMap) && (
            <div className={`col-lg-6 col-md-6 col-sm-6 col-12 scroll_part tablet_col ${loading && "disabled_parent"}`}>
              <ul className="header_link_read p-0">
                <li>
                  <p>
                    Amlaak <BiChevronRight className="chevronright_icon" />{" "}
                  </p>
                </li>
                <li>
                  <p>
                    Properties for {selectedAction} in{" "}
                    {zoom >= 14 ? selectedSociety : selectedCity}{" "}
                  </p>
                </li>
              </ul>
              <div className="header_filter">
                {/* <h1>
                  <span>Houses for Rent in Chaklala Scheme, Rawalpindi</span>
                </h1> */}
                <div className="properties_default mt-4">
                  <span className="properties">
                    Showing {meta?.from} to {meta?.to} of {meta?.total} total
                    properties
                  </span>
                  <Dropdown>
                    <Dropdown.Toggle
                      className="default_order"
                      id="dropdown-basic"
                    >
                      Default order{" "}
                      <BsArrowDownUp style={{ color: "#ff5b00" }} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown_menu_default">
                      <Dropdown.Item href="#/action-1">
                        Default Order
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Newest Listed
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Oldest Listed
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Highest Price
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Lowest Price
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <hr />
              <div className="row" ref={listingRef}>
                {propertyListing &&
                  Object.values(propertyListing)?.map((property) => {
                    let singleProperty = Object.values(property)[0];
                    return singleProperty.map((property) => {
                      return (
                        <div
                          key={property.id}
                          className="col-lg-6 col-md-6 col-sm-6 col-12 house_rent_col"
                        >
                          <div
                            onClick={() => {
                              setSelectedProperty(property);
                              setShowSingleProperty(true);
                            }}
                            onMouseEnter={() => {
                              const latLng = {
                                lat: property.lat,
                                lng: property.lng,
                              };
                              setCenteroid(latLng);
                            }}
                            className="hole_card"
                          >
                            <Card className="house_rent_card mb-3">
                              <div className="list-card-variable-text list-card-img-overlay">
                                {formatDistance(
                                  subDays(new Date(property.created_at), 0),
                                  currentDate,
                                  { addSuffix: true }
                                )}
                              </div>
                              <Card.Img
                                variant="top"
                                src={
                                  property.image_url ||
                                  "../images/house_rent2.jpg"
                                }
                                className="house_rent_card_img"
                                loading="lazy"
                              />
                              <div className="list-card-actions">
                                <button
                                  className="list-card-save"
                                  type="button"
                                  aria-label="Save"
                                >
                                  <svg>
                                    <BiHeart />
                                    <title>Save this home</title>
                                  </svg>
                                </button>
                              </div>
                              <Card.Body>
                                <Card.Title>PKR {property.price}</Card.Title>
                                <div className="card_paragraph">
                                  <p>
                                    {property.size} {property.unit} -{" "}
                                    {property.sub_type} - For {property.action}
                                  </p>

                                  <p>
                                    {property.society}, {property.city}
                                  </p>
                                </div>
                                <p className="Listing_by">
                                  LISTING BY: {property.listed_by}
                                </p>
                              </Card.Body>
                            </Card>
                          </div>
                        </div>
                      );
                    });
                  })}
              </div>
              <Pagination className="justify-content-center ">
                <Pagination.First
                  onClick={() => {
                    const page = 1;
                    changePageNumber(page);
                  }}
                  disabled={meta?.current_page === 1}
                />
                <Pagination.Prev
                  onClick={() => {
                    const page = meta?.current_page - 1;
                    changePageNumber(page);
                  }}
                  disabled={meta?.current_page === 1}
                />
                {pageNumbers?.map((pageNumber) => (
                  <Pagination.Item
                    key={pageNumber}
                    onClick={() => {
                      changePageNumber(pageNumber);
                    }}
                    active={pageNumber === meta.current_page}
                  >
                    {pageNumber}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => {
                    const page = meta?.current_page + 1;
                    changePageNumber(page);
                  }}
                  disabled={meta?.last_page === meta?.current_page}
                />
                <Pagination.Last
                  onClick={() => {
                    const page = meta?.last_page;
                    changePageNumber(page);
                  }}
                  disabled={meta?.last_page === meta?.current_page}
                />
              </Pagination>
            </div>
          )}
        </div>
      </div>
      {showSingleProperty && (
        <PropertiesModal
          show={showSingleProperty}
          setShow={setShowSingleProperty}
          properties={selectedProperty}
        />
      )}
    </>
  );
};

export default Home;
